<template>
    <Head>
        <Title>{{SEOObj.title}}</Title>
        <Meta name="description" :content="SEOObj.description" />
        <Meta property="og:title" :content="SEOObj.ogTitle" />
        <Meta property="og:description" :content="SEOObj.ogDescription" />
        <Meta property="og:image" :content="SEOObj.ogImage" />
        <Meta name="twitter:card" :content="SEOObj.twitterCard" />
    </Head>
    <div>
        <HomeHero/>
        <ListCategories/>
        <AboutCompany/>
    </div>
</template>

<script>
import HomeHero from "../components/Sections/HomeHero.vue";
import ListCategories from "../components/Sections/ListCategories.vue";
import AboutCompany from "../components/Sections/AboutCompany.vue";
import {useSEO} from "~/store/seo";


export default {
    async setup() {
        const seoStore = useSEO();

        if (!seoStore.listSEO.length) {
            await seoStore.fetchSEO();
        }
        const SEOObj = seoStore.getSEOObj('/');

        return {
            SEOObj
        }
    },
    components: {
        HomeHero,
        ListCategories,
        AboutCompany
    }
}
</script>
